import { useEffect, useRef } from 'react';

import Axios from './_instance';

// eslint-disable-next-line no-undef
let abortController = new AbortController();

export async function getHttpRequest(url, config = {}) {
  const requestConfig = {
    signal: abortController.signal,
    ...config,
  };
  return Axios.get(url, requestConfig);
}

export async function postHttpRequest(url, data, config = {}) {
  const requestConfig = {
    signal: abortController.signal,
    ...config,
  };

  return Axios.post(url, data, requestConfig);
}

export async function putHttpRequest(url, data, config = {}) {
  const requestConfig = {
    signal: abortController.signal,
    ...config,
  };

  return Axios.put(url, data, requestConfig);
}

export async function patchHttpRequest(url, data, config = {}) {
  const requestConfig = {
    signal: abortController.signal,
    ...config,
  };

  return Axios.patch(url, data, requestConfig);
}

export async function deleteHttpRequest(url, config = {}) {
  const requestConfig = {
    signal: abortController.signal,
    ...config,
  };

  return Axios.delete(url, requestConfig);
}

export function cancelOngoingHttpRequest() {
  abortController.abort();

  // regenerate AbortSignal for future HTTP calls
  // eslint-disable-next-line no-undef
  abortController = new AbortController();
}

export const useApiControllers = () => {
  const previousApiCallController = useRef(null);

  useEffect(() => {
    return () => {
      if (previousApiCallController.current) {
        previousApiCallController.current.abort();
      }
    };
  }, []);

  const abortPreviousApiCall = () => {
    if (previousApiCallController.current) {
      previousApiCallController.current.abort();
    }
  };

  const getNewApiController = () => {
    abortPreviousApiCall();

    const newApiController = new AbortController();
    previousApiCallController.current = newApiController;

    return newApiController;
  };

  return { getNewApiController };
};
