import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import validateAuthState from '../../../utils/auth/validateAuthState';
import { LOGIN } from '../constant/ROUTE';
// import validateAuthState from '../../utils/auth/validateAuthState';

export default function Page({ route }) {
  const location = useLocation();

  useEffect(() => {
    document.title = route?.meta?.title ? `${route.meta.title} - Signal` : 'Signal';
  }, [route?.meta?.title]);

  if (route?.meta?.requiresAuth && !validateAuthState()) {
    // Redirect the user to login screen if no valid access token available
    return <Redirect to={{ pathname: LOGIN, state: { location } }} />;
  }

  // Call the guard function with the `next` function as the callback
  if (route?.beforeEnter && typeof route.beforeEnter === 'function') {
    return route.beforeEnter(next);
  } else {
    return next();
  }

  // Define the `next` function to be called by the guard function after validating the access conditions
  function next(newRoute) {
    if (newRoute && (typeof newRoute === 'string' || typeof newRoute === 'object')) {
      return <Redirect to={newRoute} />;
    }

    return route?.element;
  }
}

Page.propTypes = {
  route: PropTypes.object,
};
