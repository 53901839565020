import { Box, InputLabel, TextField, Typography } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import RequiredAsterik from 'src/app/components/common/requiredAsterik';
import {
  ActiveStepsKeys,
  getErrorKey,
  getViewDisabledContractClass,
  showError,
} from 'src/app/sales/pages/contractCreation/helper';
import { MAX_4_DIGIT_VALUE, MAX_5_DIGIT_VALUE } from 'src/utils/constants';
import { checkAndAddDot } from 'src/utils/string/addDotInEnd';

import { useStyles } from '../onDemandServices';

export const MONTHLY_RATE = 0;

const rates = {
  0: 'pricePerMonth',
  1: 'pricePerHit',
  2: 'pricePerHour',
};

const systemServicesConstant = {
  SYSTEM: 'system',
  DISPATCH_REQUEST: 'Dispatch Request',
  EXTRA_JOB: 'Extra Job',
};
const SystemService = ({
  item,
  index,
  data,
  setData,
  errorMessages,
  setErrorMessages,
  isPublished,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const NA = t('commonText.nA');
  const rate = item.rate;

  /**
   * conditon to show steric *
   * Hide for Dispatch Request & Extra Job
   */
  const requiredCondition = !(
    item.intent === systemServicesConstant.SYSTEM &&
    (item.title === systemServicesConstant.DISPATCH_REQUEST ||
      item.title === systemServicesConstant.EXTRA_JOB)
  );

  /**
   * common function to update data to formDat object
   */
  const updateFormHandler = useCallback(
    (name, value) => {
      setData((prevState) => {
        const newData = prevState.map((item, i) => {
          if (i === index) {
            return { ...item, [name]: value };
          }
          return item;
        });
        return newData;
      });
    },
    [setData],
  );

  const inputChangedHandler = (event) => {
    let { name, value } = event.target;

    /**
     * It will only store floating values upto 2 decimel places
     */
    if (name === 'price' && !value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
      return;
    }

    if (value) {
      const {
        [getErrorKey({ activeStep: ActiveStepsKeys.ON_DEMAND_SERVICES, index, key: name })]: _key,
        ...rest
      } = errorMessages;
      setErrorMessages(rest);
    }

    if (rate !== MONTHLY_RATE && value > MAX_4_DIGIT_VALUE) value = MAX_4_DIGIT_VALUE;

    if (rate === MONTHLY_RATE && value > MAX_5_DIGIT_VALUE) value = MAX_5_DIGIT_VALUE;

    updateFormHandler(name, value);
  };

  const getError = () => {
    return showError({
      activeStep: ActiveStepsKeys.ON_DEMAND_SERVICES,
      index,
      key: 'price',
      errorMessages,
    });
  };

  return (
    <Box className={classNames(classes.demandPricesRow, getViewDisabledContractClass(isPublished))}>
      <Box className={classes.priceLeftSide}>
        <Box className={classes.priceLeftSideInner}>
          <Box className={classes.labelCount}>{index + 1}.</Box>
          <Box className={classes.labelTextColum}>
            <Typography variant="body2" className={classes.labelHeading}>
              {item?.title || NA}
            </Typography>
            <Typography variant="body2" className={classes.labelSubText}>
              {item?.description}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.priceRightSide}>
        <Box className={classes.priceRightSideInner}>
          <Box className={classes.DemandInline}>
            <InputLabel htmlFor="price">
              {t(`sales.contract.${rates[rate]}`)} {requiredCondition && <RequiredAsterik />}
            </InputLabel>
            <TextField
              name="price"
              id="price"
              fullWidth
              placeholder={t(`sales.contract.add${rates[rate]}`)}
              type="number"
              className={classes.inputField}
              onChange={inputChangedHandler}
              value={data[index]?.price}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              error={!!getError()}
              helperText={getError() && checkAndAddDot(getError())}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SystemService.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  data: PropTypes.array,
  setData: PropTypes.number,
  errorMessages: PropTypes.object,
  setErrorMessages: PropTypes.func,
  isPublished: PropTypes.bool,
};

export default SystemService;
