import common from './common.json';
import ho from './ho.json';
import obx from './obx.json';
import sales from './sales.json';

const en = {
  ...common,
  obx,
  ho,
  sales,
};

export default en;
